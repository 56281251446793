import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwPcLocations = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>MINI DRIVING EXPERIENCE LOCATIONS AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>How many MINI Driving Experience locations are there?</h5>
                <p>We currently have two locations in California and South Carolina.</p>
              </div>
              <div>
                <h5>Where is the MINI Driving Experience located?</h5>
                <p>We currently have two locations in California and South Carolina.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/>(888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/>(888) 345-4269</p>
              </div>
              <div>
                <h5>Is the MINI Driving Experience affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Can I pick up a new MINI at the BMW Performance Center?</h5>
                <p>No. Currently, only new BMW models are available for our delivery experience.</p>
              </div>
              <div>
                <h5>Does the MINI Driving Experience offer classes at other locations?</h5>
                <p>Currently, classes are only available at our South Carolina and California locations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwPcLocations;